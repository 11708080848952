<script setup>
import { ref, reactive, defineEmits, onMounted } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'
import errorHandler from '@/helpers/error-handler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanService from '@/services/LaporanService'
import SupplierService from '@/services/SupplierService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanService()
const supplierService = new SupplierService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
// let isLoadingExportExcel = ref(false)
let form = reactive({
  date_to: new Date(),
  supplier: null,
})
let submitted = ref(false)
let list_supplier = ref([])

const rules = {
  date_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

onMounted(async () => {
  const res = await supplierService.get()
  list_supplier.value = res.data
})

function onExport(isFormValid) {
  isLoadingExport.value = true
  const endDate = dayjs(form.date_to).format('YYYY-MM-DD')
  const supp_id = form.supplier === null ? 0 : form.supplier

  submitted.value = true
  if (!isFormValid) {
    isLoadingExport.value = false
    return
  }

  laporanService
    .SaldoHutangJatuhTempo(endDate, supp_id)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-saldo-hutang.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Laporan Saldo Hutang', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.date_to = null
  form.date_from = null
  form.supplier = null
}
</script>

<template>
  <fx-report-container
    title="Laporan Saldo Hutang Belum Jatuh Tempo"
    :loading="isLoadingExport"
    @toggle="onToggle"
    @export="onExport"
  >
    <template #content>
      <form @submit.prevent="onExport(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-4">
            <Dropdown
              v-model="form.supplier"
              :options="list_supplier"
              optionValue="id"
              optionLabel="name"
              placeholder="Pilih Supplier"
              class="w-full"
              filter
              showClear
            />
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.date_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.date_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.date_to.$invalid && submitted) ||
                v$.date_to.$pending.$response
              "
              class="p-error"
              >{{ v$.date_to.required.$message }}</small
            >
          </div>          
          <div class="field col-12 md:col-5">
            <Button
              :loading="isLoadingExport"
              type="submit"
              icon="pi pi-file-pdf"
              class="ml-2"
              label="Cetak"
            />
            <!-- <Button
              :loading="isLoadingExportExcel"
              type="button"
              icon="pi pi-file-excel"
              label="Export"
              class="p-button-success ml-2"
              @click="onExportExcel(!v$.$invalid)"
            /> -->
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
